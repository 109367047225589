<template>
  <v-row class="justify-space-between mb-4">
    <v-col>
      <p style="color: #4477ad; font-size: 26px">
        {{ data.namePage }}
        <span style="margin-left: 5px">({{ getList.length }})</span>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      getList(_, getters) {
        return getters[this.getPathStore + '/getList']
      },
    }),
    getPathStore() {
      return this.data.path + 'Store'
    },
  },
}
</script>
